@import "../../vendor/mdui/variable.less";

.mc-comments {
  .comments {
    min-height: 148px;

    @media (max-width: @screen-xs-max) {
      border-right: none;
      border-left: none;
      border-radius: 0;
    }
  }
}
