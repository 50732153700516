@import "../../../../vendor/mdui/variable.less";

#page-index .items-container {
  position: relative;
  float: left;
  box-sizing: border-box;
  width: 50%;
  margin-bottom: 24px;
  padding-right: 8px;
  padding-left: 8px;

  @media (max-width: @screen-sm-max) {
    width: 100%;
  }

  @media (max-width: @screen-xs-max) {
    padding: 0;
  }

  .items {
    @media (max-width: @screen-xs-max) {
      border-right: none;
      border-left: none;
      border-radius: 0;
    }
  }

  .header {
    @media (max-width: @screen-sm-max) {
      padding: 0 16px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    height: 52px;
    padding: 0 24px 0 64px;
    font-size: 15px;
    text-decoration: none;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    transition: background-color .18s cubic-bezier(.4,0,.2,1);
    margin-right: 70px;
    @media (max-width: @screen-sm-max) {
      padding: 0 16px 0 48px;
    }

    @media (max-width: @screen-xs-max) {
      border-top: none;
    }

    .mdui-theme-layout-dark & {
      border-top-color: rgba(255, 255, 255, 0.12);
    }

    &:hover {
      background-color: @color-hover-black;

      .mdui-theme-layout-dark & {
        background-color: @color-hover-white;
      }
    }

    &:active {
      background-color: #eee;

      .mdui-theme-layout-dark & {
        background-color: #484848;
      }
    }

    .title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .items_item {
    position: relative;
  }
  .shield {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #1a73e8;
  }

  .mc-user-popover {
    position: absolute;
    left: 24px;
    width: 20px;
    height: 20px;

    @media (max-width: @screen-sm-max) {
      left: 16px;
    }

    &>.avatar {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px 20px;
      border-radius: 50%;
    }
  }
}
