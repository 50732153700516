@import "../../../../../../vendor/mdui/variable.less";

.mc-appbar .toolbar .search-icon {
  display: none;

  @media (max-width: @screen-xs-max) {
    display: flex;
    width: 40px;
    min-width: 40px;
    height: 40px;
  }
}
