.mc-nav {
  display: flex;
  justify-content: space-between;
  margin: 16px 0 ;

  .back {
    i {
      font-size: 20px;
    }
  }
}
