@import "../../vendor/mdui/variable.less";

#page-question {
  max-width: 846px;

  &.mdui-container {
    padding-top: 0;
  }

  .question {
    padding: 0 64px;

    .title {
      position: relative;
      margin: 0;
      padding: 36px 0 24px;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
    }

    .content {
      padding: 48px 0 32px;
    }

    .mc-topics-bar {
      padding-bottom: 16px;
    }

    .actions {
      display: flex;
      margin-right: -64px;
      margin-left: -64px;
      padding: 8px 64px;
      background-color: rgba(0, 0, 0, .04);
    }
  }

  .mc-list-header {
    margin-top: 32px;
    margin-bottom: 20px;
  }

  .all-answers {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin: 16px 0;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, .12);
    border-radius: 8px;
    opacity: .56;

    .mdui-theme-layout-dark & {
      border-color: rgba(255, 255, 255, .12);
    }

    &:hover {
      opacity: 1;
    }
  }

  .answers {
    min-height: 148px;
  }

  @media (max-width: @screen-xs-max) {
    .question {
      padding: 0 16px;
      border-right: none;
      border-left: none;
      border-radius: 0;

      .title {
        padding: 16px 0;
        font-size: 22px;
        line-height: 32px;
      }

      .content {
        padding: 28px 0;
      }

      .mc-topics-bar {
        padding-bottom: 8px;
      }

      .actions {
        margin-right: -16px;
        margin-left: -16px;
        padding-right: 16px;
        padding-left: 16px;
      }
    }

    .mc-list-header {
      margin-top: 20px;
      margin-bottom: 12px;
      padding: 0 16px;

      .mdui-btn {
        padding-right: 0;
      }
    }

    .answers {
      border-right: none;
      border-left: none;
      border-radius: 0;
    }
  }
}
