.mc-report-dialog {
  width: 280px;

  .mdui-dialog-title {
    padding-bottom: 16px;

    button {
      float: right;
      margin-top: -10px;
    }
  }

  .mdui-dialog-content {
    padding: 0 0 8px;
  }

  .mdui-list-item {
    padding-left: 24px;
  }

  .mdui-list-item-content {
    margin-left: 2px;
  }

  .mdui-textfield {
    margin-right: 24px;
    margin-left: 24px;
    padding-top: 8px;
  }
}
