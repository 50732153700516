@import '../../vendor/mdui/variable.less';

#page-index {
  &.mdui-container {
    padding-top: 0;
  }

  &>.header {
    margin: 0 8px;
    padding: 4px 4px 0 !important;

    @media (max-width: @screen-xs-max) {
      margin: 0;
      padding: 0 16px !important;
    }
  }

  .topics-wrapper {
    margin-bottom: 24px;
  }

  .items-wrapper {
    &::after{
      display: table;
      clear: both;
      content: '';
    }
  }
}
