@import "../../../../vendor/mdui/variable.less";

.mc-comments-dialog,
.mc-comments-page {
  .new-comment {
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    width: 100%;
    padding: 12px 0;
    background-color: #fff;

    .mdui-theme-layout-dark & {
      background-color: @layout-dark-color-4;
    }

    textarea {
      flex: 1;
      max-height: 60vh;
      margin-right: 16px;
      overflow: auto;
      font-size: inherit;
      border-bottom: none !important;
      box-shadow: 0 1px 0 0 rgba(0,0,0,.42);

      .mdui-theme-layout-dark & {
        box-shadow: 0 1px 0 0 rgba(255, 255, 255, .7);
      }

      &:hover,
      &:focus {
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.87);

        .mdui-theme-layout-dark & {
          box-shadow: 0 2px 0 0 #fff;
        }
      }
    }

    .submit {
      width: 76px;
    }
  }
}
