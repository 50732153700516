@import "../../vendor/mdui/variable.less";

.mc-comments-dialog {
  position: fixed;
  top: 3%;
  z-index: 2001;
  max-width: 750px;
  height: 100vh;
  max-height: 94%;

  @media (max-width: @screen-xs-max) {
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    border-radius: 0;
  }

  .mc-comments {
    height: 100%;
  }

  .mc-list-header {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 60px;
    margin: 0 !important;
    padding: 0 16px;
    border-bottom: 1px solid rgba(0, 0, 0, .12);

    .close {
      display: none;
    }

    button {
      padding-right: 0;
    }

    .mdui-theme-layout-dark & {
      border-bottom-color: rgba(255, 255, 255, .12);
    }

    @media (max-width: @screen-sm-max) {
      height: 56px;
    }

    @media (max-width: @screen-xs-max) {
      padding-left: 8px;

      .close {
        display: inline-block;
        margin-right: 16px;
      }
    }
  }

  .comments-wrapper {
    height: calc(~'100% - 120px');
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (max-width: @screen-sm-max) {
      height: calc(~'100% - 116px');
    }

    .mc-loaded {
      margin-bottom: 24px !important;
    }
  }

  .comments {
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
  }

  .mc-comments > .new-comment {
    position: absolute;
    bottom: 0;
    box-shadow: 0 -1px 3px rgba(26, 26, 26, .1);

    .mdui-theme-layout-dark & {
      box-shadow: 0 -1px 3px rgba(228, 228, 228, .1);
    }
  }

  .comments > .item,
  .mc-comments > .new-comment {
    padding-right: 24px;
    padding-left: 24px;

    @media (max-width: @screen-xs-max) {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}
