@import "../../../../vendor/mdui/variable.less";

#page-index .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  padding: 0 24px;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;

  i {
    font-size: 18px;
  }
}
