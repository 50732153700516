@import '../../vendor/mdui/variable.less';
@import "../../utils/mixin.less";

.mc-users-dialog {
  max-width: 530px;
  height: 94% !important;
  max-height: 720px !important;

  .mdui-dialog-title {
    padding: 16px 24px;
    line-height: 36px;
  }

  .mdui-dialog-content,
  .mdui-list {
    padding: 0;
  }

  .mdui-dialog-content {
    padding-bottom: 62px;
  }

  .item {
    position: relative;
  }

  .mc-follow {
    position: absolute;
    top: 18px;
    right: 24px;
  }

  .mdui-list-item {
    padding: 8px 24px;
  }

  .close {
    display: none;
  }

  @media (max-width: @screen-xs-max) {
    .mc-dialog-fullscreen();

    .mdui-dialog-title {
      padding: 10px 8px;
      font-size: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      .mdui-theme-layout-dark & {
        border-bottom-color: rgba(255, 255, 255, .12);
      }
    }

    .mdui-list-item {
      padding-right: 16px;
      padding-left: 16px;
    }

    .close {
      display: inline-block;
      float: left;
      margin-right: 16px;
    }
  }
}
