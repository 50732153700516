.mc-user-line {
  position: relative;
  height: 40px;

  .avatar {
    float: left;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px 40px;
    border-radius: 50%;
  }

  .username {
    margin-left: 24px;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-decoration: none;
  }

  .headline {
    margin-left: 64px;
    overflow: hidden;
    font-size: 13px;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .more {
    position: absolute;
    top: 0;
    right: 0;
  }

  .time {
    font-size: 13px;
    line-height: 20px;
  }
}
