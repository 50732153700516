.mc-avatar-upload,
.mc-cover-upload {
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.mc-avatar-upload .upload-btn,
.mc-cover-upload .upload-btn {
  display: block;
  width: 48px;
  min-width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.26) !important;
}
.mc-avatar-upload .upload-btn i,
.mc-cover-upload .upload-btn i {
  color: #fff !important;
  opacity: 0.9;
}
.mc-avatar-upload .upload-btn:hover,
.mc-cover-upload .upload-btn:hover,
.mc-avatar-upload .upload-btn:active,
.mc-cover-upload .upload-btn:active {
  background-color: rgba(0, 0, 0, 0.26) !important;
}
.mc-avatar-upload .upload-btn:hover i,
.mc-cover-upload .upload-btn:hover i,
.mc-avatar-upload .upload-btn:active i,
.mc-cover-upload .upload-btn:active i {
  opacity: 1;
}
.mc-avatar-upload .upload-btn + input[type="file"],
.mc-cover-upload .upload-btn + input[type="file"] {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}
