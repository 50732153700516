.mc-follow {
  i {
    font-size: 21px !important;
  }

  &.mdui-btn-dense {
    i {
      font-size: 19px !important;
    }
  }
}
