/**
 * =============================================================================
 * ************   Progress Circular 圆形进度条   ************
 * =============================================================================
 */

.mdui-spinner {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 28px;
  animation: mdui-spinner 1568ms linear infinite;
}

@keyframes mdui-spinner {
  to {
    transform: rotate(360deg);
  }
}

.mdui-spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  border-color: @color-default-500;
  opacity: 0;
  opacity: 1;
  animation: mdui-spinner-layer-fill-unfill-rotate 5332ms @animation-curve-default infinite both;
}

// custom start
.layout-theme({
  .mdui-spinner-layer {
    border-color: @primaryColorDark;
  }
});
// custom end

/* .mdui-spinner-layer-1 {
  border-color: @color-blue-400 !important;
  animation:
    mdui-spinner-layer-fill-unfill-rotate 5332ms @animation-curve-default infinite both,
    mdui-spinner-layer-1-fade-in-out 5332ms @animation-curve-default infinite both;
}

.mdui-spinner-layer-2 {
  border-color: @color-red-500 !important;
  animation:
    mdui-spinner-layer-fill-unfill-rotate 5332ms @animation-curve-default infinite both,
    mdui-spinner-layer-2-fade-in-out 5332ms @animation-curve-default infinite both;
}

.mdui-spinner-layer-3 {
  border-color: @color-yellow-600 !important;
  animation:
    mdui-spinner-layer-fill-unfill-rotate 5332ms @animation-curve-default infinite both,
    mdui-spinner-layer-3-fade-in-out 5332ms @animation-curve-default infinite both;
}

.mdui-spinner-layer-4 {
  border-color: @color-green-500 !important;
  animation:
    mdui-spinner-layer-fill-unfill-rotate 5332ms @animation-curve-default infinite both,
    mdui-spinner-layer-4-fade-in-out 5332ms @animation-curve-default infinite both;
} */

@keyframes mdui-spinner-layer-fill-unfill-rotate {
  12.5% { transform: rotate(135deg);  }
  25%   { transform: rotate(270deg);  }
  37.5% { transform: rotate(405deg);  }
  50%   { transform: rotate(540deg);  }
  62.5% { transform: rotate(675deg);  }
  75%   { transform: rotate(810deg);  }
  87.5% { transform: rotate(945deg);  }
  to    { transform: rotate(1080deg); }
}

/* @keyframes mdui-spinner-layer-1-fade-in-out {
  from { opacity: 1; }
  25%  { opacity: 1; }
  26%  { opacity: 0; }
  89%  { opacity: 0; }
  90%  { opacity: 1; }
  100% { opacity: 1; }
}

@keyframes mdui-spinner-layer-2-fade-in-out {
  from { opacity: 0; }
  15%  { opacity: 0; }
  25%  { opacity: 1; }
  50%  { opacity: 1; }
  51%  { opacity: 0; }
}

@keyframes mdui-spinner-layer-3-fade-in-out {
  from { opacity: 0; }
  40%  { opacity: 0; }
  50%  { opacity: 1; }
  75%  { opacity: 1; }
  76%  { opacity: 0; }
}

@keyframes mdui-spinner-layer-4-fade-in-out {
  from { opacity: 0; }
  65%  { opacity: 0; }
  75%  { opacity: 1; }
  90%  { opacity: 1; }
  100% { opacity: 0; }
} */

.mdui-spinner-gap-patch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;

  .mdui-spinner-circle {
    left: -450%;
    box-sizing: border-box;
    width: 1000%;
  }
}

.mdui-spinner-circle-clipper {
  position: relative;
  display: inline-block;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;

  .mdui-spinner-circle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    width: 200%;
    height: 100%;
    border-color: inherit;
    border-style: solid;
    border-width: 3px;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    animation: none;
  }

  &.mdui-spinner-left {
    float: left;

    .mdui-spinner-circle {
      left: 0;
      border-right-color: transparent !important;
      transform: rotate(129deg);
      animation: mdui-spinner-left-spin 1333ms @animation-curve-default infinite both;
    }
  }

  &.mdui-spinner-right {
    float: right;

    .mdui-spinner-circle {
      left: -100%;
      border-left-color: transparent !important;
      transform: rotate(-129deg);
      animation: mdui-spinner-right-spin 1333ms @animation-curve-default infinite both;
    }
  }
}

@keyframes mdui-spinner-left-spin {
  from { transform: rotate(130deg); }
  50%  { transform: rotate(-5deg);  }
  to   { transform: rotate(130deg); }
}

@keyframes mdui-spinner-right-spin {
  from { transform: rotate(-130deg); }
  50%  { transform: rotate(5deg);    }
  to   { transform: rotate(-130deg); }
}


/**
 * =============================================================================
 * ************   Spinner linear 主色   ************
 * =============================================================================
 */
& {
  .loop-primary-theme(@counter-color) when (@counter-color > 0) {
    .loop-primary-theme((@counter-color - 1));
    @colorName: extract(@globalPrimaryColors, @counter-color);
    @color: 'color-@{colorName}-500';

    .mdui-theme-primary-@{colorName} when not (@colorName = null) {
      .mdui-spinner-layer {
        border-color: @@color;
      }
    }

  }
  .loop-primary-theme(length(@globalPrimaryColors));
}
