/**
 * =============================================================================
 * ************   Dialog 提示框   ************
 * =============================================================================
 */

@import '../button.less';

.mdui-dialog {
  position: fixed;
  right: 0;
  left: 0;
  z-index: @z-index-dialog;
  display: none;
  box-sizing: border-box;
  width: 92%;
  min-width: 180px;
  max-width: 728px;
  max-height: 90%;
  margin: auto;
  overflow: hidden;
  color: #000;
  background-color: #fff;
  // custom start
  // border-radius: 2px;

  border-radius: 8px;
  // custom end
  transform: scale(0.95);
  opacity: 0;
  transition-duration: .3s;
  transition-property: transform, opacity, visibility;
  will-change: top, opacity, transform;

  @media (min-width: @screen-sm-min) {
    width: 85%;
    max-height: 85%;
  }
  @media (min-width: @screen-md-min) {
    width: 80%;
    max-height: 80%;
  }

  .mdui-shadow(24);
}

/* 打开提示框 */
.mdui-dialog-open {
  transform: scale(1);
  opacity: 1;
}

/* 提示框标题 */
.mdui-dialog-title {
  box-sizing: border-box;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
}

/* 标题固定在顶部 */
.mdui-dialog>.mdui-dialog-title {
  padding: 24px 24px 20px;
}

/* 提示框内容 */
.mdui-dialog-content {
  box-sizing: border-box;
  padding: 24px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
  line-height: 1.5;
  -webkit-overflow-scrolling: touch;
}

/* 标题随内容滚动 */

/* .mdui-dialog-content>.mdui-dialog-title {
  padding-bottom: 20px;
} */

.mdui-dialog-title + .mdui-dialog-content {
  padding-top: 0;
}

/* 提示框底部按钮 */
.mdui-dialog-actions {
  box-sizing: border-box;
  padding: 8px;
  text-align: right;

  .mdui-btn {
    min-width: 64px;
    margin-left: 8px;
    .mdui-text-color(@color-accent-default-name);

    &:first-child {
      margin-left: 0;
    }
  }
}

/* 底部按钮滚随内容动 */

/* .mdui-dialog-content>.mdui-dialog-actions {
  margin: 0 -24px -24px -24px;
  padding-top: 32px;
} */

/* 使提示框底部按钮竖排 */

/* .mdui-dialog-actions-stacked {
  padding: 8px 0 8px 0;
  .mdui-btn {
    width: 100%;
    height: 48px;
    margin: 0;
    line-height: 48px !important;
    text-align: right;
    border-radius: 0;
  }
} */


/**
 * =============================================================================
 * ************   Dialog 强调色   ************
 * =============================================================================
 */
& {
  .loop-accent-theme(@counter-color) when (@counter-color > 0) {
    .loop-accent-theme((@counter-color - 1));
    @colorName: extract(@globalAccentColors, @counter-color);

    .mdui-theme-accent-@{colorName} when not (@colorName = null) {
      .mdui-dialog-actions {
        .mdui-btn {
          .mdui-text-color(@colorName);
        }
      }
    }

  }
  .loop-accent-theme(length(@globalAccentColors));
}


/**
 * =============================================================================
 * ************   Dialog dark   ************
 * =============================================================================
 */
.layout-theme({
  .mdui-dialog {
    color: #fff;
    background-color: @layout-dark-color-4;
  }
  .mdui-dialog-content {
    color: rgba(255, 255, 255, 0.7);
  }

  // custom start
  .mdui-dialog-actions .mdui-btn {
    color: @primaryColorDark;
  }
  // custom end
});
