.mc-empty {
  box-sizing: border-box;
  width: 100%;
  margin-top: 80px;
  padding: 108px 16px 64px;
  text-align: center;
  background-image: url("../../static/image/following_empty.png");
  background-repeat: no-repeat;
  background-position: center top;

  .title {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, .54);
    font-size: 18px;

    .mdui-theme-layout-dark & {
      color: rgba(255, 255, 255, .87);
    }
  }

  .description {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, .54);
    font-size: 15px;
    line-height: 24px;

    .mdui-theme-layout-dark & {
      color: rgba(255, 255, 255, .64);
    }
  }
}
