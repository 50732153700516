/**
 * =============================================================================
 * ************   Appbar 应用栏   ************
 * =============================================================================
 */

@import './toolbar.less';
@import './headroom.less';

.mdui-appbar {
  z-index: @z-index-appbar;
  .mdui-shadow(4);
}

/* appbar 固定在顶部 */
.mdui-appbar-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transition-timing-function: @animation-curve-linear-out-slow-in;
  transition-duration: 0.3s;
  transition-property: left, right;
  will-change: left, right;
}

/* 左侧留出抽屉栏的距离 */

/* .mdui-appbar-inset.mdui-appbar-fixed {
  .mdui-drawer-body-left & {
    @media (min-width: @screen-md-min) {
      left: @drawer-width-md;
    }
  }

  .mdui-drawer-body-right & {
    @media (min-width: @screen-md-min) {
      right: @drawer-width-md;
    }
  }
} */

/* 含工具栏 */
.mdui-appbar-with-toolbar {
  padding-top: @appbar-height-xs-portrait;

  // 平板和桌面
  @media (min-width: @screen-sm-min) {
    padding-top: @appbar-height-sm;
  }

  // 手机横屏
  @media (orientation: landscape) and (max-width: @screen-xs-max-landscape) {
    padding-top: @appbar-height-xs-landscape;
  }
}

/* 含 Tab */
.mdui-appbar-with-tab {
  padding-top: 48px;
}

/* 含大 Tab  */

/* .mdui-appbar-with-tab-larger {
  padding-top: 72px;
} */

/* 含工具栏和 Tab */
.mdui-appbar-with-toolbar.mdui-appbar-with-tab {
  padding-top: @appbar-height-xs-portrait + 48px;

  // 平板和桌面
  @media (min-width: @screen-sm-min) {
    padding-top: @appbar-height-sm + 48px;
  }

  // 手机横屏
  @media (orientation: landscape) and (max-width: @screen-xs-max-landscape) {
    padding-top: @appbar-height-xs-landscape + 48px;
  }
}

/* 含工具栏和大 Tab */

/* .mdui-appbar-with-toolbar.mdui-appbar-with-tab-larger {
  padding-top: @appbar-height-xs-portrait + 72px;

  // 平板和桌面
  @media (min-width: @screen-sm-min) {
    padding-top: @appbar-height-sm + 72px;
  }

  // 手机横屏
  @media (orientation: landscape) and (max-width: @screen-xs-max-landscape) {
    padding-top: @appbar-height-xs-landscape + 72px;
  }
} */


/**
 * =============================================================================
 * ************   Appbar dark   ************
 * =============================================================================
 */
.layout-theme({
  .mdui-appbar {
    &>[class*="mdui-color-"]:not(.mdui-color-transparent) {
      color: #fff !important;
      background-color: @layout-dark-color-2 !important;
    }
  }
});


/**
 * =============================================================================
 * ************   自定义修改   ************
 * =============================================================================
 */
.mdui-appbar {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
}
