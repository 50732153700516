/**
 * =============================================================================
 * ************   Button 按钮   ************
 * =============================================================================
 */

/* 默认为 Flat 扁平按钮 */
.mdui-btn,
.mdui-fab {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 66px;
  height: @button-height;
  margin: 0;
  padding: 0 16px;
  overflow: hidden;
  color: inherit;
  font-weight: 500;
  font-size: 14px;
  line-height: @button-height;
  letter-spacing: 0.04em;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;
  background: transparent;
  border: none;
  //border-radius: 2px;
  // start mdclub 自定义的样式

  border-radius: 4px;
  // end mdclub 自定义的样式
  outline: none;
  cursor: pointer;
  transition: all .2s @animation-curve-default,
  box-shadow .2s @animation-curve-fast-out-linear-in;
  user-select: none;

  // 移除点击延迟触发
  touch-action: manipulation;
  will-change: box-shadow;
  zoom: 1;
  -webkit-user-drag: none;

  // 透明按钮 hover、active 时颜色加深
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:not(.mdui-ripple):active {
    background-color: rgba(0, 0, 0, 0.165);
  }

  // 带背景色的按钮 hover、active 时增加透明度

  /* &[class*="mdui-color-"]:hover {
    opacity: 0.87;
  } */

  &:not(.mdui-ripple)[class*="mdui-color-"]:active {
    opacity: 0.76;
  }
}

// custom start
.mdui-btn.mdui-color-theme:not(.mdui-btn-raised) {
  color: @primaryColor !important;
  background-color: transparent !important;

  &:hover {
    background-color: rgba(red(@primaryColor), green(@primaryColor), blue(@primaryColor), 0.1) !important;
  }

  &:not(.mdui-ripple) {
    &:active,
    &.mdui-btn-active {
      background-color: rgba(red(@primaryColor), green(@primaryColor), blue(@primaryColor), 0.165) !important;
    }
  }

  &.mdui-ripple .mdui-ripple-wave {
    background-color: rgba(red(@primaryColor), green(@primaryColor), blue(@primaryColor), 0.3) !important;
  }
}

.layout-theme({
  .mdui-btn.mdui-color-theme:not(.mdui-btn-raised) {
    color: @primaryColorDark !important;

    &:hover {
      background-color: rgba(red(@primaryColorDark), green(@primaryColorDark), blue(@primaryColorDark), 0.1) !important;
    }

    &:not(.mdui-ripple) {
      &:active,
      &.mdui-btn-active {
        background-color: rgba(red(@primaryColorDark), green(@primaryColorDark), blue(@primaryColorDark), 0.165) !important;
      }
    }

    &.mdui-ripple .mdui-ripple-wave {
      background-color: rgba(red(@primaryColorDark), green(@primaryColorDark), blue(@primaryColorDark), 0.3) !important;
    }
  }
});
// custom end

/* 按钮内的图标 */
.mdui-btn {
  //.mdui-icon-right
  .mdui-icon-left
  {
    &,
    &::before {
      height: inherit;
      font-size: 1.3em;
      line-height: inherit;
    }
  }

  .mdui-icon-left {
    float: left;
    margin-right: 0.4em;
  }

  /* .mdui-icon-right {
    float: right;
    margin-left: 0.4em;
  } */
}

input.mdui-btn[type="submit"] {
  appearance: none;
}

/* Raised button 浮动按钮 */
.mdui-btn-raised {
  .mdui-shadow(2);

  &:hover {
    .mdui-shadow(4);
  }

  &:active {
    .mdui-shadow(8);
  }
}

/* 禁用按钮 */
//.mdui-fab
.mdui-btn
{
  &[disabled] {
    &,
    &:hover,
    &:active,
    &:focus {
      color: rgba(0, 0, 0, .26) !important;
      background-color: transparent !important;
      cursor: default !important;
      opacity: 1 !important;
      .mdui-shadow(0) !important;

      .mdui-icon {
        color: rgba(0, 0, 0, .26) !important;
      }
    }
  }
}

/* 禁用状态浮动按钮和浮动操作按钮 */
//.mdui-fab
.mdui-btn-raised
{
  &[disabled] {
    &,
    &:hover,
    &:active,
    &:focus {
      background-color: rgba(0, 0, 0, .12) !important;
      .mdui-shadow(2) !important;
    }
  }
}

/* 加粗按钮文本 */

/* .mdui-btn-bold {
  font-weight: bold;
} */

/* 图标按钮 */
.mdui-btn-icon {
  width: 36px;
  min-width: 36px;
  height: 36px;
  margin-right: 0;
  margin-left: 0;
  padding: 0;
  overflow: hidden;
  font-size: 24px;
  line-height: normal;
  border-radius: 50%;

  .mdui-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    line-height: 24px;
    transform: translate(-12px, -12px);
  }

  &.mdui-ripple {
    transform: translateZ(0);
  }
}

/* 按钮 100% 宽度 */

/* .mdui-btn-block {
  display: block;
  width: 100%;
} */

/* 密集型按钮 */
.mdui-btn-dense {
  height: 32px;
  font-size: 13px;
  line-height: 32px;

  &.mdui-btn-icon {
    width: 32px;
    min-width: 32px;
  }
}

/* 按钮组 */

/* .mdui-btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  .mdui-btn {
    float: left;
    min-width: inherit;
    padding: 0 12px;
    color: rgba(0, 0, 0, 0.54);
    border-radius: 0;

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-left: 1px solid transparent;
      content: ' ';
    }

    &:first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;

      &::before {
        border-left: none;
      }
    }

    &:last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }

    // 激活状态
    &.mdui-btn-active {
      color: rgba(0, 0, 0, 0.87);
      background-color: rgba(0, 0, 0, 0.215);

      &+.mdui-btn-active {
        &::before {
          border-left: 1px solid rgba(0, 0, 0, 0.145);
        }
      }
    }
  }
} */


/**
 * =============================================================================
 * ************   Button dark   ************
 * =============================================================================
 */
.layout-theme({
  // 透明按钮 hover、active 时颜色变浅
  .mdui-btn,
  .mdui-fab {
    // 透明按钮 hover、active 时颜色变浅
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
    &:not(.mdui-ripple):active {
      background-color: rgba(255, 255, 255, 0.165);
    }

    // 带背景的按钮 hover、active 时增加透明度

    /* &[class*="mdui-color-"]:hover {
      opacity: 0.87;
    } */

    &:not(.mdui-ripple)[class*="mdui-color-"]:active {
      opacity: 0.76;
    }
  }

  // 禁用按钮
  //.mdui-fab
  .mdui-btn
  {
    &[disabled] {
      &,
      &:hover,
      &:active,
      &:focus {
        color: @color-white-icon-disabled !important;
        background-color: transparent !important;
        .mdui-icon {
          color: @color-white-icon-disabled !important;
        }
      }
    }
  }

  // 禁用状态浮动按钮和浮动操作按钮
  .mdui-btn-raised,
  .mdui-fab {
    &[disabled] {
      &,
      &:hover,
      &:active,
      &:focus {
        background-color: rgba(255, 255, 255, .12) !important;
      }
    }
  }
});
