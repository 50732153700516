.mc-tab {
  a {
    flex-direction: row;

    span {
      margin-left: 6px;
      transform: scale(.9);
      opacity: .56;
    }
  }
}
