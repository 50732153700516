@import "../../../../../../vendor/mdui/variable.less";

.mc-appbar .toolbar .notification {
  @media (max-width: @screen-xs-max) {
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-right: -4px;
  }

  .count {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    margin-top: -14px;
    margin-left: 2px;
    background: #1a73e8;
    border-radius: 50%;
  }
}
