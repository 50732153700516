/**
 * =============================================================================
 * ************   Chip 纸片   ************
 * =============================================================================
 */

 @chip-height: 32px;

 .mdui-chip {
   display: inline-block;
   box-sizing: border-box;
   height: @chip-height;
   margin: 2px 0;
   color: inherit;
   white-space: nowrap;
  //  background-color: #e0e0e0;
   border-radius: (@chip-height / 2);
   cursor: pointer;
   user-select: none;
   .mdui-hoverable(2);

   &:active {
     background-color: #d6d6d6;
   }
 }

 /* 左侧的图标 */
 .mdui-chip-icon {
   position: relative;
   display: inline-block;
  //  width: @chip-height;
  //  height: @chip-height;
  //  margin-right: -4px;
   overflow: hidden;
   color: #fff;
   font-size: 18px;
   line-height: @chip-height;
   text-align: center;
   vertical-align: middle;
   background-color: #989898;
   border-radius: 50%;

   .mdui-icon {
     position: absolute;
     top: 4px;
     left: 4px;
     color: #fff;
   }
 }

 /* 文本 */
 .mdui-chip-title {
   display: inline-block;
   height: @chip-height;
  //  padding-right: 12px;
  //  padding-left: 12px;

   font-size: 14px;
   line-height: @chip-height;
   vertical-align: middle;
 }

 /* 删除按钮 */
 .mdui-chip-delete {
   display: inline-block;
  //  width: 24px;
  //  height: 24px;
  //  margin-right: 4px;
  //  margin-left: -8px;
   overflow: hidden;
   text-align: center;
   text-decoration: none;
   vertical-align: middle;
   border-radius: 50%;
   cursor: pointer;
   opacity: .54;
   transition: opacity .25s @animation-curve-default;
   will-change: opacity;

   &:hover,
   &:focus {
     opacity: .87;
   }
 }


 /**
  * =============================================================================
  * ************   Chip dark   ************
  * =============================================================================
  */
.layout-theme({
  .mdui-chip {
    background-color: #484848;
    &:active {
      background-color: #5d5d5d;
    }
  }
});


  /**
  * =============================================================================
  * ************   自定义修改   ************
  * =============================================================================
  */
.mdui-chip {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, .12);
  box-shadow: none !important;

  &:hover {
    background-color: rgba(0, 0, 0, .04);
  }
}

.mdui-chip-icon {
  float: left;
  width: 24px;
  height: 24px;
  margin: 3px;
}

.mdui-chip-title {
  padding-right: 12px;
  padding-left: 8px;
}

.mdui-chip-delete {
  float: right;
  width: 18px;
  height: 18px;
  margin: 6px 6px 6px -4px;

  i {
    font-size: 18px;
  }
}

.layout-theme({
  .mdui-chip {
    border-color: rgba(255, 255, 255, .12);

    &:hover {
      background-color: rgba(255, 255, 255, .08);
    }
  }
});
