@import '../../vendor/mdui/variable.less';
@import '../../utils/mixin.less';

.mc-editor {
  position: fixed;
  right: 74px;
  bottom: 0;
  z-index: 5000;
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 608px;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);

  .header {
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #404040;
    user-select: none;

    .mdui-theme-layout-dark & {
      background-color: @layout-dark-color-3;
    }
  }

  .header-title {
    display: flex;
    flex: 1;
    align-items: center;
    height: 100%;
    padding-left: 16px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }

  .header-actions {
    padding-right: 8px;
    padding-left: 8px;

    i {
      padding: 3px;
      color: #fff;
      font-size: 18px;
      font-style: normal;
      cursor: pointer;
      opacity: .6;

      &:hover {
        background-color: #737373;
        opacity: 1;
      }
    }
  }

  &.maximize:not(.minimize) {
    right: calc(~'(100% - 736px)/2');
    bottom: 4vh;
    width: 100%;
    max-width: 736px;
    height: 92vh;

    @media (max-width: 736px) {
      right: 0;
      bottom: 0;
      height: 100vh;
      border-radius: 0;
    }
  }

  &.minimize {
    width: 260px;
    height: 40px;
  }

  .body {
    flex: 1;
    padding: 0 16px;
    overflow: hidden;
    background-color: #fff;

    .mdui-theme-layout-dark & {
      background-color: @layout-dark-color-4;
    }

    .submit {
      min-width: 76px;
      margin-right: 10px;
    }
  }

  .editor-title {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    font-size: 16px;
    line-height: 34px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .08);
    outline: none;

    .mdui-theme-layout-dark & {
      border-color: rgba(255, 255, 255, .12);
    }
  }

  .editor-topics {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, .08);

    .mdui-theme-layout-dark & {
      border-color: rgba(255, 255, 255, .12);
    }

    .placeholder,
    .chip-wrapper {
      display: flex;
      flex: 1;
      align-items: center;
      height: 100%;
      user-select: none;
    }

    .placeholder {
      color: rgba(0, 0, 0, .42);
      cursor: pointer;

      .mdui-theme-layout-dark & {
        color: rgba(255, 255, 255, .42);
      }
    }
  }

  .editor-toolbar {
    padding: 12px 0;
    box-shadow: inset 0 1px 0 0 rgba(100,121,143,0.122);

    .mdui-theme-layout-dark & {
      .mdui_editor-toolbar-menu-active {
        background-color: #565656 !important;
      }

      i {
        color: #fff;
      }
    }
  }

  .editor-content {
    height: calc(~'100% - 60px');
    padding: 10px 0;

    &::before {
      .mdui-theme-layout-dark & {
        color: rgba(255, 255, 255, .42);
      }
    }
  }

  &.with-title,
  &.with-topics {
    .editor-content {
      height: calc(~'100% - 108px');
    }
  }

  &.with-title.with-topics {
    .editor-content {
      height: calc(~'100% - 156px');
    }
  }

  // 手机上只有全屏和最小化两种状态，且按钮需要放大
  @media (max-width: @screen-xs-max) {
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    border-radius: 0;

    &.minimize {
      width: 100%;
      height: 56px;
    }

    .header {
      height: 56px;
    }

    .header-title {
      font-size: 16px;
    }

    .header-actions {
      i {
        padding: 8px;
        font-size: 20px;
        border-radius: 3px;

        // 手机上不显示最大化按钮
        &:nth-child(2) {
          display: none;
        }
      }
    }

    .icon-maximize {
      display: none;
    }

    // 手机上隐藏部分按钮，并放大按钮
    .mdui_editor-toolbar-menu-italic,
    .mdui_editor-toolbar-menu-code,
    .mdui_editor-toolbar-menu-ol,
    .mdui_editor-toolbar-menu-ul {
      display: none;
    }

    .mdui_editor-toolbar-menu {
      width: 36px;
      min-width: 36px;
      height: 36px;
      line-height: 36px;

      i {
        font-size: 22px;
      }
    }
  }
}
