@import "../../vendor/mdui/variable.less";

.mc-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 18px;
  }

  .mdui-menu {
    width: 236px;
  }

  @media (max-width: @screen-xs-max) {
    .title {
      font-size: 16px;
    }
  }
}
